// import { Link } from "gatsby"
import styled from "styled-components"
import { Link as LinkBase } from "../../common/Link"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const WrapperLink = styled.div`
  display: flex;
  gap: 3em;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 2.5em;
  justify-content: center;

  &.mobile {
    padding: 0.1em 0.5em;
    grid-gap: 0.5em;
  }
`

export const Title = styled.div`
  margin-bottom: 1em;
  width: 75%;

  > * {
    ${({ theme }) => theme.typography.h2};
    text-align: center;
    letter-spacing: 0px;
    color: #2b3944;
    opacity: 1;
  }
`

export const Link = styled(LinkBase)`
  font-family: ${props => props.theme.typography.body.fontFamily};
  font-weight: ${props => props.theme.typography.body.fontWeight};
  font-size: 0.9rem;
  border: 1px solid #9e9e9e;
  border-radius: 50px;
  padding: 0.5em 2em;
  a {
    color: black;
  }
`

import React from "react"
import { Wrapper, WrapperLink, Link, Title } from "./ProductsCollectionLink.style"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { v4 as uuidv4 } from "uuid"
import { ColoredLine } from "../ColorLine"

interface Props {
  id?: string
  data: { name: string, slug: string }[]
  className?: string
}

export function ProductsCollectionLink({
  id = "",
  data,
  className, children
}: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  if (!data?.length) return <></>

  return (
    <Wrapper>
      {children && (
        <Title>
          <ColoredLine />
          {children}
        </Title>
      )}

      <WrapperLink id={id} className={classNames}>
        {data.map((item: any) => {
          if (item?.slug && item?.name)
            return <Link link={'/' + item.slug} key={uuidv4()}>{item.name}</Link>
        })}
      </WrapperLink>
    </Wrapper>

  )
}

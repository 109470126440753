import styled from "styled-components"
import { WrapperMainComponent } from "../../common/Layout"

export const Wrapper = styled(WrapperMainComponent)`
  > * {
    max-width: 85em;
    margin: auto;
    padding-left: 2em;
    padding-right: 2em;
  }
  &.mobile {
    padding-left:0;
    padding-right:0;
 }

`
export const WrapperDescription = styled(Wrapper)`
  background: #fbfbfb 0% 0% no-repeat padding-box;
`
